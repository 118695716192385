import React from "react"
import { Link, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "components/layout"
import SEO from "components/seo"
import { Row, Container } from "react-bootstrap"
import Img from "gatsby-image"
import ContactForm from "components/ContactForm"



export default function AboutUs({ data }) {
  const contactusdata = data.allContentfulContactus.nodes
const companyName = contactusdata[0].companyName
const contactInfoText = contactusdata[0].contactInfo

  return (
    <Layout pageInfo={{ pageName: "page-2" }}>
    <SEO
      title="Sipariş Makinesi Paket Servis Sistemi"
      keywords={[
        `sipariş makinesi`,
        `adelsoft`,
        `paket servis sistemi`,
        `müşteri tanıma sistemi`,
        `yemek sepeti otomatik çıkartma`,
        `getir otomatik çıkartma`,
        `sipariş otomatik onaylama`,
      ]}
    />
    <Link to="/">Ana Sayfaya Dön</Link>

    <section py="3|4|6|10">
      <div variant="container">
        <div
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <p>{contactInfoText && renderRichText(contactInfoText)}</p>

          {/*     {text && (
          <p variant="text.lead" mt="2">
            {text}
          </p>
        )} */}
          {/*    {buttons} */}
        </div>
      </div>
    </section>
    <Container className="text-center">
    
      <Row className="justify-content-center my-3">
        <Img fixed={contactusdata[1].companyLogo.fixed} />
      </Row>
      <Row className="justify-content-center my-3">
        <ContactForm />
      </Row>
    </Container>
  </Layout>
  )
}

export const query = graphql`
  query AboutUsQuery {
    allContentfulContactus {
      nodes {
        companyName
        companyLogo {
          fixed(width: 170) {
            ...GatsbyContentfulFixed
          }
        }
        companyLocation {
          lat
          lon
        }
        contactInfo {
          raw
        }
      }
    }
  }
`
